<template>
  <div class="home">
    <section class="container">
      <h1 class="text--serif">Welcome to the Virtuoso Design Library</h1>
    </section>
    <section class="container">
      <h2><router-link to="/components">Component Library</router-link></h2>
      <p>Components built to the specification of the Pattern Library.</p>
    </section>
    
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
    
  }
}
</script>
